// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var TailwindMerge = require("tailwind-merge");
var JsxRuntime = require("react/jsx-runtime");

function AttributeListV5(props) {
  var __size = props.size;
  var size = __size !== undefined ? __size : "md";
  return JsxRuntime.jsx("ul", {
              children: props.data.map(function (item, index) {
                    var value = item.value;
                    return JsxRuntime.jsxs("li", {
                                children: [
                                  value !== undefined ? JsxRuntime.jsx("span", {
                                          children: value,
                                          className: "mr-1 text-gray-700 font-medium"
                                        }) : null,
                                  JsxRuntime.jsx("span", {
                                        children: item.title,
                                        className: "whitespace-nowrap"
                                      })
                                ],
                                className: "flex items-center mt-1 sm:mt-0 after:block after:content-[''] after:w-px after:h-3.5 after:bg-gray-500 after:mx-2.5 last:after:content-none"
                              }, String(index));
                  }),
              className: TailwindMerge.twMerge([
                    "flex flex-wrap leading-6 text-gray-500",
                    size === "sm" ? "text-base" : "text-lg",
                    Core__Option.getOr(props.className, "")
                  ])
            });
}

var make = AttributeListV5;

exports.make = make;
/* tailwind-merge Not a pure module */
