// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


class UnselectedUnionMemberError extends Error {
     constructor(message) {
      super()
      this.name = 'UnselectedUnionMemberError'
      this.message = message
     }
   }
;

function raise(message) {
  throw new UnselectedUnionMemberError(message);
}

exports.raise = raise;
/*  Not a pure module */
