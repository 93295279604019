// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var PropertyAttributes = require("../../cap/Property/components/PropertyAttributes.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var MultiUnitPropertyAttributes_multiUnitProperty_graphql = require("../../../__generated__/MultiUnitPropertyAttributes_multiUnitProperty_graphql.bs.js");

var convertFragment = MultiUnitPropertyAttributes_multiUnitProperty_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(MultiUnitPropertyAttributes_multiUnitProperty_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, MultiUnitPropertyAttributes_multiUnitProperty_graphql.node, convertFragment);
}

var PropertyTypeFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function MultiUnitPropertyAttributes(props) {
  var match = RescriptRelay_Fragment.useFragment(MultiUnitPropertyAttributes_multiUnitProperty_graphql.node, convertFragment, props.propertyType);
  var numberOfUnits = match.numberOfUnits;
  var attributes_0 = [
    String(numberOfUnits),
    numberOfUnits === 1 ? "Unit" : "Units"
  ];
  var attributes_1 = props.displayPropertyType ? ({
        hd: [
          match.displayName,
          "Empty"
        ],
        tl: /* [] */0
      }) : /* [] */0;
  var attributes = {
    hd: attributes_0,
    tl: attributes_1
  };
  return JsxRuntime.jsx(PropertyAttributes.make, {
              propertyAttributes: attributes,
              size: props.size
            });
}

var make = MultiUnitPropertyAttributes;

exports.PropertyTypeFragment = PropertyTypeFragment;
exports.make = make;
/* react/jsx-runtime Not a pure module */
