// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__List = require("@rescript/core/src/Core__List.bs.js");
var AttributeListV5 = require("./AttributeListV5.bs.js");
var PropertyStrings = require("../../../common/Strings/PropertyStrings.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function PropertyAttributes(props) {
  var fn = function (param) {
    return {
            value: param[0],
            title: PropertyStrings.displayPropertyAttributeSuffix(param[1])
          };
  };
  var attributes = Core__List.map(props.propertyAttributes, fn);
  return JsxRuntime.jsx(AttributeListV5.make, {
              data: Core__List.toArray(attributes),
              size: props.size
            });
}

var displayAttrSuffix = PropertyStrings.displayPropertyAttributeSuffix;

var make = PropertyAttributes;

exports.displayAttrSuffix = displayAttrSuffix;
exports.make = make;
/* AttributeListV5 Not a pure module */
