// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

function unwrap_fragment_propertyType(u) {
  var v = u.__typename;
  switch (v) {
    case "CondoProperty" :
        return {
                NAME: "CondoProperty",
                VAL: u
              };
    case "SingleFamilyProperty" :
        return {
                NAME: "SingleFamilyProperty",
                VAL: u
              };
    default:
      return {
              NAME: "UnselectedUnionMember",
              VAL: v
            };
  }
}

function wrap_fragment_propertyType(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

var fragmentConverter = {"__root":{"propertyType_SingleFamilyProperty":{"f":""},"propertyType_CondoProperty":{"f":""},"propertyType":{"u":"fragment_propertyType"}}};

var fragmentConverterMap = {
  fragment_propertyType: unwrap_fragment_propertyType
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RentalPropertyAttributes_property",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "propertyType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CondoPropertyAttributes_condoProperty"
              }
            ],
            "type": "CondoProperty",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SingleFamilyPropertyAttributes_singleFamilyProperty"
              }
            ],
            "type": "SingleFamilyProperty",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "propertyType"
    }
  ],
  "type": "RentalPropertyV5",
  "abstractKey": null
};

exports.Types = Types;
exports.unwrap_fragment_propertyType = unwrap_fragment_propertyType;
exports.wrap_fragment_propertyType = wrap_fragment_propertyType;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
