// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function displayPropertyAttributeSuffix(s) {
  if (s === "HalfBath") {
    return "half-bath";
  } else if (s === "Bed") {
    return "bed";
  } else if (s === "Empty") {
    return "";
  } else if (s === "HalfBaths") {
    return "half-baths";
  } else if (s === "Bath") {
    return "bath";
  } else if (s === "Beds") {
    return "beds";
  } else if (s === "SqftLot") {
    return "sqft lot";
  } else if (s === "Sqft") {
    return "sqft";
  } else if (s === "Unit") {
    return "unit";
  } else if (s === "Units") {
    return "units";
  } else {
    return "baths";
  }
}

exports.displayPropertyAttributeSuffix = displayPropertyAttributeSuffix;
/* No side effect */
