// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Image = require("next/image").default;

var Legacy = {
  make: $$Image
};

var make = $$Image;

exports.Legacy = Legacy;
exports.make = make;
/* Legacy Not a pure module */
