// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../ErrorHandling/FragmentErrorBoundary.bs.js");
var LotPropertyAttributes = require("./LotPropertyAttributes.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CondoPropertyAttributes = require("./CondoPropertyAttributes.bs.js");
var UnselectedUnionMemberError = require("../ErrorHandling/UnselectedUnionMemberError.bs.js");
var MultiUnitPropertyAttributes = require("./MultiUnitPropertyAttributes.bs.js");
var SingleFamilyPropertyAttributes = require("./SingleFamilyPropertyAttributes.bs.js");
var ForSalePropertyAttributes_property_graphql = require("../../../__generated__/ForSalePropertyAttributes_property_graphql.bs.js");

var convertFragment = ForSalePropertyAttributes_property_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ForSalePropertyAttributes_property_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ForSalePropertyAttributes_property_graphql.node, convertFragment);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function ForSalePropertyAttributes$WithoutErrorBoundary(props) {
  var __displayPropertyType = props.displayPropertyType;
  var size = props.size;
  var displayPropertyType = __displayPropertyType !== undefined ? __displayPropertyType : true;
  var property = RescriptRelay_Fragment.useFragment(ForSalePropertyAttributes_property_graphql.node, convertFragment, props.property);
  var match = property.propertyType;
  var variant = match.NAME;
  if (variant === "SingleFamilyProperty") {
    return JsxRuntime.jsx(SingleFamilyPropertyAttributes.make, {
                propertyType: match.VAL.fragmentRefs,
                size: size,
                displayPropertyType: displayPropertyType
              });
  } else if (variant === "UnselectedUnionMember") {
    return UnselectedUnionMemberError.raise("Unselected property type: " + match.VAL);
  } else if (variant === "CondoProperty") {
    return JsxRuntime.jsx(CondoPropertyAttributes.make, {
                propertyType: match.VAL.fragmentRefs,
                size: size,
                displayPropertyType: displayPropertyType
              });
  } else if (variant === "LotProperty") {
    return JsxRuntime.jsx(LotPropertyAttributes.make, {
                propertyType: match.VAL.fragmentRefs,
                size: size,
                displayPropertyType: displayPropertyType
              });
  } else {
    return JsxRuntime.jsx(MultiUnitPropertyAttributes.make, {
                propertyType: match.VAL.fragmentRefs,
                size: size,
                displayPropertyType: displayPropertyType
              });
  }
}

var WithoutErrorBoundary = {
  make: ForSalePropertyAttributes$WithoutErrorBoundary
};

function ForSalePropertyAttributes(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "property attributes",
              children: JsxRuntime.jsx(ForSalePropertyAttributes$WithoutErrorBoundary, {
                    property: props.property,
                    size: props.size,
                    displayPropertyType: props.displayPropertyType
                  })
            });
}

var make = ForSalePropertyAttributes;

exports.PropertyFragment = PropertyFragment;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* react/jsx-runtime Not a pure module */
