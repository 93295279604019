// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

function unwrap_fragment(u) {
  var v = u.__typename;
  switch (v) {
    case "ForSalePropertyV5" :
        return {
                NAME: "ForSalePropertyV5",
                VAL: u
              };
    case "RentalPropertyV5" :
        return {
                NAME: "RentalPropertyV5",
                VAL: u
              };
    default:
      return {
              NAME: "UnselectedUnionMember",
              VAL: v
            };
  }
}

function wrap_fragment(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

var fragmentConverter = {"__root":{"RentalPropertyV5":{"f":""},"ForSalePropertyV5":{"f":""},"":{"u":"fragment"}}};

var fragmentConverterMap = {
  fragment: unwrap_fragment
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "priceFormat",
        "variableName": "priceFormat"
      },
      {
        "kind": "Variable",
        "name": "priceRangeFormat",
        "variableName": "priceRangeFormat"
      }
    ],
    "kind": "FragmentSpread",
    "name": "PropertyPrice_price"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "FULL",
      "kind": "LocalArgument",
      "name": "priceFormat"
    },
    {
      "defaultValue": "FULL",
      "kind": "LocalArgument",
      "name": "priceRangeFormat"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyPrice_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "ForSalePropertyV5",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "RentalPropertyV5",
      "abstractKey": null
    }
  ],
  "type": "PropertyV5",
  "abstractKey": "__isPropertyV5"
};
})());

exports.Types = Types;
exports.unwrap_fragment = unwrap_fragment;
exports.wrap_fragment = wrap_fragment;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
