// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var TailwindMerge = require("tailwind-merge");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UnselectedUnionMemberError = require("../ErrorHandling/UnselectedUnionMemberError.bs.js");
var PropertyPrice_price_graphql = require("../../../__generated__/PropertyPrice_price_graphql.bs.js");
var PropertyPrice_property_graphql = require("../../../__generated__/PropertyPrice_property_graphql.bs.js");
var PropertyPrice_propertyPrice_graphql = require("../../../__generated__/PropertyPrice_propertyPrice_graphql.bs.js");

var convertFragment = PropertyPrice_property_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(PropertyPrice_property_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PropertyPrice_property_graphql.node, convertFragment);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = PropertyPrice_price_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(PropertyPrice_price_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PropertyPrice_price_graphql.node, convertFragment$1);
}

var PropertyPriceFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

var convertFragment$2 = PropertyPrice_propertyPrice_graphql.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(PropertyPrice_propertyPrice_graphql.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PropertyPrice_propertyPrice_graphql.node, convertFragment$2);
}

var PropertyPricePriceFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  use: use$2,
  useOpt: useOpt$2
};

function PropertyPrice$PriceInternal$View(props) {
  var size = props.size;
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: TailwindMerge.twMerge([
                    "text-tan-gold",
                    size !== undefined ? (
                        size === "medium" ? "text-2xl font-medium" : (
                            size === "small" ? "text-xl font-semibold" : "text-3xl font-medium"
                          )
                      ) : "text-2xl font-medium sm:text-3xl ",
                    Core__Option.getOr(props.className, "")
                  ])
            });
}

var View = {
  make: PropertyPrice$PriceInternal$View
};

function PropertyPrice$PriceInternal$PriceDisplay(props) {
  var __children = props.children;
  var className = props.className;
  var size = props.size;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var propertyPrice = RescriptRelay_Fragment.useFragment(PropertyPrice_propertyPrice_graphql.node, convertFragment$2, props.propertyPrice);
  var variant = propertyPrice.NAME;
  if (variant === "UnselectedUnionMember") {
    return UnselectedUnionMemberError.raise("Unselected price: " + propertyPrice.VAL);
  }
  if (variant === "ExactPrice") {
    return JsxRuntime.jsxs(PropertyPrice$PriceInternal$View, {
                children: [
                  propertyPrice.VAL.price.formatted,
                  children
                ],
                size: size,
                className: className
              });
  }
  var match = propertyPrice.VAL;
  return JsxRuntime.jsxs(PropertyPrice$PriceInternal$View, {
              children: [
                match.minPrice.formatted,
                " - ",
                match.maxPrice.formatted,
                children
              ],
              size: size,
              className: className
            });
}

var PriceDisplay = {
  make: PropertyPrice$PriceInternal$PriceDisplay
};

function PropertyPrice$PriceInternal$Price(props) {
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var property = RescriptRelay_Fragment.useFragment(PropertyPrice_price_graphql.node, convertFragment$1, props.property);
  return JsxRuntime.jsx(PropertyPrice$PriceInternal$PriceDisplay, {
              propertyPrice: property.price.fragmentRefs,
              size: props.size,
              className: props.className,
              children: Caml_option.some(children)
            });
}

var Price = {
  make: PropertyPrice$PriceInternal$Price
};

var PriceInternal = {
  PropertyPriceFragment: PropertyPriceFragment,
  PropertyPricePriceFragment: PropertyPricePriceFragment,
  View: View,
  PriceDisplay: PriceDisplay,
  Price: Price
};

function PropertyPrice$WithoutErrorBoundary(props) {
  var className = props.className;
  var size = props.size;
  var property = RescriptRelay_Fragment.useFragment(PropertyPrice_property_graphql.node, convertFragment, props.property);
  var variant = property.NAME;
  if (variant === "ForSalePropertyV5") {
    return JsxRuntime.jsx(PropertyPrice$PriceInternal$Price, {
                property: property.VAL.fragmentRefs,
                size: size,
                className: className
              });
  } else if (variant === "UnselectedUnionMember") {
    return UnselectedUnionMemberError.raise("Unselected member: " + property.VAL);
  } else {
    return JsxRuntime.jsx(PropertyPrice$PriceInternal$Price, {
                property: property.VAL.fragmentRefs,
                size: size,
                className: className,
                children: "/month"
              });
  }
}

var WithoutErrorBoundary = {
  make: PropertyPrice$WithoutErrorBoundary
};

function PropertyPrice(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "price",
              children: JsxRuntime.jsx(PropertyPrice$WithoutErrorBoundary, {
                    property: props.property,
                    size: props.size,
                    className: props.className
                  })
            });
}

var Price$1;

var make = PropertyPrice;

exports.PropertyFragment = PropertyFragment;
exports.PriceInternal = PriceInternal;
exports.Price = Price$1;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* tailwind-merge Not a pure module */
