// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function formatFloat(f) {
  if (f % 1.0 === 0.0) {
    return String(f | 0);
  } else {
    return f.toString();
  }
}

exports.formatFloat = formatFloat;
/* No side effect */
